.slide-home {
  width: 100%;
  position: relative;
  height: 180px;
  img {
    height: 180px;
  }
}

.swiper-slide {
  a {
    display: flex;
    align-items: center;
    height: 195px;
    width: 95%;
    margin: 0 auto;
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adm-center-popup {
  --background-color: var(
    --adm-center-popup-background-color,
    var(--adm-color-background)
  );
  --border-radius: var(--adm-center-popup-border-radius, 8px);
  --max-width: var(--adm-center-popup-max-width, 75vw);
  --min-width: var(--adm-center-popup-min-width, 280px);
  --z-index: var(--adm-center-popup-z-index, 1000);
  position: fixed;
  z-index: var(--z-index);
}

.adm-center-popup .adm-center-popup-mask {
  z-index: 0;
}

.adm-center-popup-wrap {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: var(--min-width);
  max-width: var(--max-width);
  transform: translate(-50%, -50%);
}

.adm-center-popup-body {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
}

.adm-center-popup-close {
  position: absolute;
  z-index: 100;
  right: 0.21333rem;
  top: 0.21333rem;
  cursor: pointer;
  padding: 0.10667rem;
  font-size: 0.48rem;
  color: var(--adm-color-weak);
}

.adm-dialog {
  --z-index: var(--adm-dialog-z-index, 1000);
  ---z-index: var(--z-index);
}

.adm-dialog .adm-center-popup {
  --z-index: var(---z-index);
}

.adm-dialog-body {
  width: 100%;
  max-height: 70vh;
  font-size: var(--adm-font-size-6);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.adm-dialog-body > * {
  flex: none;
}

.adm-dialog-body > .adm-dialog-content {
  flex: auto;
}

.adm-dialog-body:not(.adm-dialog-with-image) {
  padding-top: 0.53333rem;
}

.adm-dialog-image-container {
  margin-bottom: 0.32rem;
  max-height: 40vh;
}

.adm-dialog-header {
  margin-bottom: 0.21333rem;
  padding: 0 0.32rem;
}

.adm-dialog-title {
  margin-bottom: 0.21333rem;
  padding: 0 0.32rem;
  font-weight: 700;
  font-size: var(--adm-font-size-10);
  line-height: 0.66667rem;
  text-align: center;
}

.adm-dialog-content {
  padding: 0 0.32rem 0.53333rem;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: var(--adm-font-size-7);
  line-height: 1.4;
  color: var(--adm-color-text);
}

.adm-dialog-content-empty {
  padding: 0;
  height: 0.32rem;
}

.adm-dialog-footer {
  -webkit-user-select: none;
  user-select: none;
}

.adm-dialog-footer .adm-dialog-action-row {
  display: flex;
  align-items: stretch;
  border-top: 0.01333rem solid var(--adm-color-border);
}

.adm-dialog-footer .adm-dialog-action-row > * {
  flex: 1;
}

.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  padding: 0.26667rem;
  font-size: var(--adm-font-size-10);
  line-height: 0.66667rem;
  border-radius: 0;
  border-right: solid 0.01333rem var(--adm-color-border);
}

.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button-bold {
  font-weight: 700;
}

.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button:last-child {
  border-right: none;
}

.adm-dialog-image-container {
  overflow-y: auto;
}

.personal-page {
  min-height: 100%;
  background: URL("../img/user_bg.png") no-repeat;
  width: 100%;
  background-size: 100%;
  color: #ffffff;
  padding-bottom: 100px;
  margin-top: -50px;

  .container {
    width: 90%;
  }

  .grid {
    display: flex;
    justify-content: space-around;
    padding: 5px;
  }

  .uni-flex {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .uni-row {
    border-radius: 20px;
    padding: 6px;
    margin: 33px auto;
    width: 100%;
  }

  .grid {
    background-color: #eb7500;
    margin-bottom: 5px;
    border-radius: 10px;
    color: #fff;
    padding-top: 10px;

    a {
      color: #ffffff;

      span {
        font-size: 14px;
      }
    }
  }

  .uni-flex {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  .grid__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: auto;
    border-radius: 5px;
  }

  .grid__name {
    font-size: 14px;
    color: #fff;
  }

  .grid__name__red {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
  }
}

.personal-page .header {
  height: 4.53333rem;
  box-sizing: border-box;
  background-size: 100% 100%;
  padding: 0.26667rem;
}

.personal-page .header .company-info {
  margin-bottom: 0.26667rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-page .header .company-info .company-name {
  font-size: 0.64rem;
  font-weight: 700;
}

.personal-page .header .company-info .company-desc {
  font-size: 0.32rem;
  margin-top: 0.05333rem;
}

.personal-page .header .user-card {
  border-radius: 0.26667rem;
  color: #000;
  padding: 0.26667rem;
  height: 3.8rem;
  position: relative;
  box-sizing: border-box;
}

.personal-page .header .user-card .level-img {
  position: absolute;
  right: 0.26667rem;
  width: 1.6rem;
  height: 0.8rem;
  top: 1.92rem;
}

.personal-page .header .user-info {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.personal-page .header .user-info .vip-icon {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 0.23333rem;
  position: absolute;
  top: calc(50% - 0.5rem);
}

.personal-page .header .user-info .user-option {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 120px;
  font-size: 16px;

  .text-small {
    span {
      font-size: 13px;
    }
  }
}

.personal-page .header .user-info .user-option-item span {
  font-size: 25px;
  font-weight: 600;
  &:first-child {
    margin-right: 5px;
  }
}

.personal-page .container {
  margin: 0 auto 0.26667rem;
}

.personal-page .container .cell-list {
  background-color: #ffffff;
  border-radius: 10px;
}

.personal-page .container .cell-list .cell-item {
  display: flex;
  color: #333;
  box-sizing: border-box;
  padding: 0.32rem 0.4rem;
  text-decoration: none;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.personal-page .container .cell-list .cell-item:last-child {
  border: none;
}

.personal-page .container .cell-list .cell-item .icon {
  font-size: 16px;
  flex: none;
  margin-right: 0.26667rem;
  color: var(--theme-color);
}

.personal-page .container .cell-list .cell-item .name {
  flex: 1;
  font-size: 15px;
  color: #696969;
}

.personal-page .container .cell-list .cell-item .arrow {
  font-size: 13px;
  color: #576b95;
}

.personal-page .btn-wrap {
  margin-top: 0.53333rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
}

.recharge-page .top {
  padding-bottom: 10%;
  padding-top: 10%;
  background: #00b077;
}

.recharge-page .top p {
  color: #fff;
  font-size: 0.48rem;
  text-align: center;
}

.recharge-page .btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
}

.withdrawal-page {
  min-height: 100%;
  padding: 45px 0 60px;
  background: #ffffff;
}

.withdrawal-page .top {
  background-color: #00b077;
  padding: 1.06667rem 0;
}

.withdrawal-page .top p {
  font-size: 0.48rem;
  color: #fff;
  text-align: center;
}

.withdrawal-page .name {
  padding: 0.32rem;
  color: #1a1a1a;
  text-align: center;
  font-size: 0.42667rem;
}

.withdrawal-page .withdrwalForm .adm-list-item-content-prefix {
  padding-right: 0.10667rem;
  flex-shrink: 0;
  width: auto;
}

.withdrawal-page .withdrwalForm .adm-list-item-content-prefix .label {
  font-size: 15px;
  color: #353535;
  font-weight: 600;
}

.withdrawal-page .withdrwalForm .adm-form-item-child-inner .input,
.withdrawal-page .withdrwalForm .adm-form-item-child-inner input {
  font-size: 0.37333rem;
}

.withdrawal-page .footer-btn {
  width: 100%;
  box-sizing: border-box;
  padding: 0.8rem 0.53333rem 0.53333rem;
}

.recharge-record {
  min-height: 100%;
}

.recharge-record .list-item {
  box-sizing: border-box;
  padding: 0.13333rem 0.4rem;
  border-bottom: 0.02667rem solid #ededed;
}

.recharge-record .list-item .top {
  display: flex;
  justify-content: flex-end;
}

.recharge-record .list-item .top .status-text {
  font-size: 0.45333rem;
  font-weight: 700;
  color: #999;
}

.recharge-record .list-item .top .status-text.yellow {
  color: #eb7d00;
}

.recharge-record .list-item .top .status-text.red {
  color: #cf3138;
}

.recharge-record .list-item .top .status-text.green {
  color: #2caa00;
}

.recharge-record .list-item .bottom {
  margin-top: 0.10667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recharge-record .list-item .bottom .time {
  color: #999;
  font-size: 0.42667rem;
}

.recharge-record .list-item .bottom .value {
  font-size: 0.4rem;
  color: #333;
}

.adm-infinite-scroll {
  color: var(--adm-color-weak);
  padding: 0.48rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--adm-font-size-main);
}

.adm-infinite-scroll-failed-text {
  display: inline-block;
  margin-right: 0.21333rem;
}

.adm-pull-to-refresh-head {
  overflow: hidden;
  position: relative;
}

.adm-pull-to-refresh-head-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: var(--adm-color-weak);
  display: flex;
  justify-content: center;
  align-items: center;
}

.win-result {
  min-height: 100%;
  background-color: #f5f5f5;
}

.win-result .total-opiton,
.win-result .time {
  height: 1.12rem;
  background-color: #fff;
  border-bottom: 0.02667rem solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.26667rem;
  font-size: 0.4rem;
  font-weight: 700;
}

.win-result .total-opiton {
  justify-content: space-between;
}

.win-result .total-opiton span {
  color: red;
}

.level-page {
  min-height: calc(100vh - 105px);
  width: 100%;
  font-size: 0;
  padding: 15px;
  background-image: URL("../img/bg_game.jpg");
  background-repeat: no-repeat;
  background-position: top;

  a {
    margin-bottom: 25px;
    display: flex;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: fill;
  }
}

.target {
  animation-name: rightToLeft;
  animation-duration: 35s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(400px);
  }

  100% {
    transform: translateX(-1250px);
  }
}

.targetUp {
  animation-name: downToUp;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes downToUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-265px);
  }
}

.service-index {
  .service-box {
    padding: 15px;
    margin: 15px;
    background-color: #ffffff;
    border-radius: 10px;
  }

  .service__img {
    width: 100px;
    height: 80px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .service__icon_bg {
    background-image: URL("../img/service-bg.png");
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
  }

  .service-item {
    display: flex;
    border-bottom: solid 1px #e7e7e7;
    padding: 20px 0;
    justify-content: left;
    position: relative;
  }

  .service-item .service__icon {
    width: 50px;
    height: 40px;
    background-image: URL("../img/icon/icon-n.png");
    background-position: 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .service-item .service-item__text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .service-item .service-item__btn {
    background-color: var(--theme-color);
    text-align: center;
    color: #fff;
    border-radius: 100px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 10px;
    position: absolute;
    right: 0;
  }

  .uni-page-wrapper {
    display: block;
    height: 100%;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 500px;
    margin: 0 auto;
  }

  .uni-h4 {
    font-size: 18px;
    font-weight: 700;
    color: #666;
  }

  .uni-h6 {
    color: #8b8b8b;
  }
}

.recharge-index {
  padding-top: 150px;

  .uni-list {
    display: flex;
    position: relative;
    flex-direction: column;
    color: #333;
    margin-top: 25px;
  }

  .uni-list-item {
    margin-bottom: 10px;
    align-items: center;
    font-size: 16px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 5px 0 5px 15px;
    border-bottom: 1px solid #d9d9d9;
  }

  .uni-list-item__container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 15px 12px 0;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  .uni-list-item__icon {
    margin-right: 9px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .uni-list-item__icon-img {
    height: 26px;
    width: 26px;
    background-image: URL("../img/icon/usdt.png");
    background-size: contain;
  }

  .recharge-index {
    padding-top: 220px;
  }

  .uni-list-item__content {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
    color: #3b4144;
  }

  .uni-list-item__content-title {
    font-size: 14px;
    color: #3b4144;
    overflow: hidden;
  }

  .uni-list-item__extra {
    /* width: 25%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #ffffff;

    svg {
      font-size: 13px;
    }
  }
}

.recharge-index .head__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 160px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 20px;
}

.recharge-index .head__row,
.recharge-index .head__p {
  flex-wrap: wrap;
}

.recharge-index .head__title {
  width: 100%;
  display: block;
  line-height: 20px;
  font-size: 11px;
}

.recharge-index .head__nameid {
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}

.room_header {
  margin: 1px auto;
  background-color: #f7f7f7;
  justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.room_header .room_header_left {
  margin: 1px;
  padding: 10px;
  flex-grow: 2;
}

.room_header_info_money {
  color: #007aff;
  font-size: 16px;
}

.room_header_info {
  margin: 3px;
  font-size: 12px;
  word-wrap: break-word;
}

.pt-40 {
  padding-top: 40px;
}

.content {
  margin: 0 auto;
  color: #333;
  padding-top: 40px;
  max-width: 500px;
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
  }

  > div {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
}

.search_view {
  display: flex;
  width: 95%;
  height: 33px;
  margin: 6px auto;
  justify-content: center;
}

.search_view .inputview {
  width: 133px;
  margin: 6px;
  padding: 3px;
  font-size: 0.8em;
  border-radius: 6px;
  background-color: #323a5d;
  text-align: center;
  height: 33px;
}

.uni-inline-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  background-color: #31385a !important;
}

.uni-card-link {
  color: #007aff;
}

.uni-h6 {
  font-size: 12px;
  color: #ccc;
}

.room-index {
  padding-top: 45px;
  .uni-card-link {
    text-align: left;
    line-height: 25px;
  }

  .uni-h4 {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
  }

  .uni-h6 {
    color: #ffffff;
  }

  .uni-inline-right {
    line-height: 25px;
    text-align: right;
    display: block;
  }

  .uni-inline-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    background-color: #e6751a !important;
  }

  .uni-inline-item {
    justify-content: space-between;
    padding: 10px;
  }
}

.uni-flex {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.uni-card {
  display: flex;
  flex: 1;
  padding: 10px 20px;
  border-radius: 0;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.uni-swiper-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  padding: 10px;
}

.uni-swiper-slides {
  /*position: absolute;*/
  /*left: 0;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
}

.uni-swiper-slide-frame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.uni-card .image-view {
  width: 90%;
  margin: 5px auto;
  border-radius: 18px;
  height: 125px;
}

.image-view-primary,
.image-view-0 {
  background-image: URL("../img/room/primary.png");
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image-view-vip,
.image-view-1 {
  background-image: URL("../img/room/vip.png");
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image-view-superior,
.image-view-2 {
  background-image: URL("../img/room/superior.png");
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image-view-premium,
.image-view-3 {
  background-image: URL("../img/room/premium.png");
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.uni-row {
  border-radius: 20px;
  padding: 6px;
  margin: 33px auto;
  width: 90%;
}

.uni-h3 {
  font-size: 24px;
  font-weight: 700;
  color: #f5f5f5;
}

.title {
  font-size: 12px;
  color: #fff;
}

.title-mg {
  margin-top: 30px;
}

.room-index .uni-card {
  margin: 5px 0;
}

.pc-index .uni-inline-item.uni-page-head {
  height: 80px;
  text-align: left;
}

.open-info {
  height: auto;
  padding: 5px;
  background-color: #31395c;
  width: 100%;
  border-top: solid 1px #000;
}

.cell_dashed {
  padding: 5px;
  border-bottom: solid 1px #151734;
  justify-content: center;
}

.pc-index .text {
  margin: 5px;
  text-align: center;
  color: #999;
  width: auto;
}

.gametext {
  display: flex;
  align-items: center;
  margin-left: 5px;
  justify-content: center;
  color: #60a3ff;
}

.uni-table {
  width: 100%;
}

.uni-table th {
  font-weight: normal;
  padding: 5px;
  background-color: #323b5c;
  width: 25%;
}

.pc-index .uni-table th,
.pc-index .uni-table td {
  width: 25%;
}

.uni-load-more {
  text-align: center;
  padding: 30px;
}

.bottom-view2 {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  background-color: #040927;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.uni-common-mt {
  margin-top: 15px;
}

.bottom-view2 .title {
  color: #007aff;
  width: 20%;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.title-item.active {
  background-color: #31395c;
}

.uni-transaction {
  height: 425px;
  overflow: hidden;
}

.uni-transaction-content {
  height: 900px;
}

table {
  border-collapse: collapse;
}

.uni-table {
  width: 100%;
}
.uni-table th {
  font-weight: normal;
  padding: 5px;
  background-color: #ffae00;
}
.pc-index .uni-table th,
.pc-index .uni-table td {
  width: 25%;
}

.uni-table td {
  padding: 10px;
}

.uni-table th,
.uni-table td {
  text-align: center;
  font-size: 12px;
  border: none;
  outline: none;
  color: #ffffff;
}
