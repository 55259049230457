.mobile-toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000000000000;
  .toast {
    max-width: 200px;
    font-size: 14px;
    color: white;
    text-align: center;
    background-color: black;
    padding: 1rem;
    border-radius: 6px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    opacity: 0.8;
  }
}