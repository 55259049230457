.game-pk3 {
  position: relative;
  padding-bottom: 100px;

  .board-game {
    padding-top: 45px;
    color: #222428;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }

  &.loading {
    background: #ffffff;
  }

  .uni-pc {
    width: 100%;
  }

  .TZbtn {
    background-color: #ff5100;
    padding: 2px;
    height: 33px;
    width: 75px;
    color: #fff;
    border-radius: 10px;
    border: #ca6c00 1px solid;
  }

  .input-wrapper {
    width: 80%;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .uni-investment {
    background-color: #9b6200;
    color: #ffffff;
    border: #c59a24 1px solid;
    border-radius: 6px;
    height: 33px;
    margin: 1px;
    font-size: 0.8em;
    &::placeholder {
      color: rgb(218, 218, 218);
    }
  }

  .game-header {
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    max-width: 500px;
    z-index: 999;
    width: 100%;
    height: 58px;
    background-color: #1273f6;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 5px;
      background: #cd160e;
      color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 4px;
      padding: 4px 11px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      .btn-his {
        box-shadow: none;
        outline: 0;
        border: none;
        background: transparent;
        font-size: 22px;
      }

      .btn-info {
        box-shadow: none;
        outline: 0;
        border: none;
        background: transparent;
        color: #ffffff;
        font-size: 25px;
      }
    }
  }

  .game-lead {
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 132, 0, 1) 35%,
      rgba(255, 171, 0, 1) 100%
    );

    .uni-h6 {
      color: #ffffff;
    }
  }

  .modal-history {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;

    .mask {
      position: absolute;
      background-color: rgba(53, 53, 53, 0.2);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .modal-content {
      top: 145px;
      background-color: #31385a;
      border-bottom: solid 1px #17192f;
      position: relative;
      z-index: 99;
      width: 100%;
      height: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
    }

    .list-result {
      font-size: 13px;

      table {
        width: 100%;
        color: #ffffff;

        .lotteryNumber {
          gap: 2px;

          .ball {
            color: #ffffff;
          }

          .dau {
            color: #ffffff;
          }
        }

        tr {
          td {
            padding: 3px;
            text-align: center;

            &.info {
              span {
                color: #ffffff;
              }
            }

            &.result {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .game-board {
    background-color: #f1f1f1;
    padding-top: 178px;
    padding-bottom: 100px;

    .msga {
      width: 80%;
      margin: 0 auto;
      background-color: #999999;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      line-height: 26px;
      padding: 10px;
      border-radius: 10px;
    }
  }

  .game-info {
    position: relative;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ffae52;

    .user-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      img {
        width: 52px;
        height: 52px;
        object-fit: cover;
      }
    }

    p {
      font-size: 16px;
    }

    h5 {
      font-size: 18px;
      font-weight: 400;
    }

    .text-center {
      text-align: center;
    }

    a {
      color: #ffffff;
    }

    .item {
      flex: 1 1 auto;
      width: 50%;
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;
      padding: 10px;

      .game-no {
        font-size: 18px;
        margin-bottom: 5px;
      }

      .time {
        display: inline-block;
        font-weight: 600;
        b {
          color: #fff;
          font-size: 14px;
          border-radius: 2px;
        }
      }

      .new-game {
        background-color: #fea931;
        color: #222428;
        font-size: 13px;
        font-weight: 600;

        span {
          padding: 6px;
        }
      }

      &:first-child {
        align-items: flex-start;
      }
    }
  }

  .game-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    border-bottom: 1px solid #ffa600;
    font-size: 16px;

    .text {
      margin: 5px;
      text-align: center;
      color: #ffffff;
      width: auto;
    }
  }

  .adm-notice-bar {
    background-color: #31345a;
    color: #ffffff;
  }

  .player-list {
    background-color: #a86103;
    overflow: hidden;
    height: 350px;

    .inner-wrap {
      overflow: hidden;
    }

    .uni-table {
      position: relative;
      overflow: hidden;
      z-index: 99;
      th,
      td {
        font-size: 11px;
        padding: 4px 10px;
      }

      &.inner-wrap {
        z-index: 90;
      }
    }

    .blue {
      text-align: center;
      color: #ffee00;
      font-size: 13px;
    }
  }

  .bg-game {
    padding: 10vh 10px;
  }

  .list-ball {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

    .item {
      background-color: #f1f1f1;
      width: calc(25% - 5px);
      height: 60px;
      font-size: 16px;
      border: 1px solid #e0e0e0;
      color: #535252;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 6px;
      gap: 5px;

      &.disabled {
        background-color: #d3d3d3;
        color: #3d3d3d;
      }

      &.select {
        border: 2px solid #fea931;

        h5 {
          color: #fea931;
        }

        p {
          color: #fea931;
        }
      }

      h5 {
        color: #535252;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin: 0;
        line-height: 16px;
      }

      p {
        color: #797979;
        font-size: 13px;
        text-align: center;
        margin: 0;
        line-height: 13px;
      }
    }
  }

  .bg-footer {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    background: #854e14;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;

    .quick-action {
      color: #a5a5a5;
      height: 44px;

      p {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        line-height: 15px;
        font-size: 15px;

        b {
          color: #fea931;
        }
      }
    }

    .title {
      color: #ffffff;
      width: 20%;
      text-align: center;
      height: 40px;
      line-height: 40px;
    }

    .title-item.active {
      background: linear-gradient(-90deg, #ff8400 35%, #ffab00 100%);
    }

    .btn-action {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;

      .input-bet {
        height: 40px;
        border-radius: 10px;
        border: 1px solid #dcdcdc;
        color: #222428;
        font-size: 20px;
        padding: 4px 11px;
      }

      .balance {
        width: 70%;
        padding: 5px;
        display: flex;
        align-items: center;

        .ant-input-number-handler-wrap {
          display: none;
        }

        .ant-input-number-input {
          color: #ffffff;
          font-size: 18px;
        }

        span {
          color: #ff3c00;
          margin-right: 5px;
        }
      }

      .btn-bet {
        border: none;
        box-shadow: none;
        outline: 0;
        height: 100%;
        color: #ffffff;
        background-color: #fb2024;
        font-size: 16px;
        font-weight: 600;
        height: 40px;
        padding: 4px 10px;
      }
    }
  }
}

.dot_loading {
  width: 25px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #c0c0c0 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.lotteryNumber {
  display: flex;
  align-items: center;

  .ball {
    color: #0011ff;
  }

  svg {
    font-size: 13px;
    color: #e2e2e2;
  }

  .dau {
    color: #0011ff;
  }
}

.Pc28-p {
  width: 100%;
}

.Pc28-pfr {
  background: linear-gradient(180deg, #ff8400 35%, #ffab00 100%);
  padding: 5px;
  width: 100%;
}

.pc-index .uni-scroll-view {
  position: relative;
  max-height: inherit;
  width: 100%;
}

.pc-index .uni-input-wrapper {
  width: 80%;
}

.uni-input-input {
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
  border-radius: 5px;
  margin-right: 5px;
  color: #ffffff;
  border: solid 1px #fff;
  padding: 4px;
  width: 95%;
}

.uni-scroll-view-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-right: -3px;
  font-size: 16px;
  text-align: center;
  padding-bottom: 20px;

  .item {
    cursor: pointer;
    min-height: 33px;
    word-wrap: break-word;
    height: 35px;
    padding: 0 15px;
    word-break: break-word;
    box-sizing: border-box;
    border: #6e5613 solid 1px;
    background-color: #9b6200;
    color: #f8f8f8;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    width: 31%;
    text-align: center;
    font-size: 18px;
    line-height: 1.8;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .item.active {
    background: linear-gradient(0deg, rgb(195 34 98) 0%, rgb(175 45 253) 100%);
    border: #fdcf00 solid 1px;
  }

  .item.item-number {
    width: 22%;
    padding: 0 5px;
  }

  .item .item_text {
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
  }

  .item .item_html {
    font-size: 0.7em;
    color: #ffd900;
  }
}
