@charset "UTF-8";

:root {
  --adm-radius-s: 0.10667rem;
  --adm-radius-m: 0.21333rem;
  --adm-radius-l: 0.32rem;
  --adm-font-size-1: 0.24rem;
  --adm-font-size-2: 0.26667rem;
  --adm-font-size-3: 0.29333rem;
  --adm-font-size-4: 0.32rem;
  --adm-font-size-5: 0.34667rem;
  --adm-font-size-6: 0.37333rem;
  --adm-font-size-7: 0.4rem;
  --adm-font-size-8: 0.42667rem;
  --adm-font-size-9: 0.45333rem;
  --adm-font-size-10: 0.48rem;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #7a7a7a;
  --adm-color-light: #666666;
  --adm-color-border: #eeeeee;
  --adm-color-box: #f5f5f5;
  --adm-color-background: #ffffff;
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, "Helvetica Neue",
    helvetica, segoe ui, arial, roboto, "PingFang SC", "miui",
    "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
  --adm-border-color: var(--adm-color-border);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --theme-color: #ff8100;
  --main-color: #0cc77f;
  --body-color: #d9e8e3;
  --content-color: #f0f2f1;
  --text-color: #3d3d3d;
  --bs-primary-rgb: rgba(0, 0, 0, 0.66);
  --swiper-pagination-top: "auto";
  --swiper-pagination-bottom: 15px;
}

html[data-prefers-color-scheme="dark"] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-border-color: var(--adm-color-border);
}

body,
html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  background-color: #f3f2f0;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
}

html {
  max-width: 750px;
  margin: 0px auto !important;
  font-size: 41.3px;
}

a,
button {
  cursor: pointer;
}

a {
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}

a:active {
  opacity: 0.8;
}

.adm-plain-anchor {
  color: unset;
  transition: none;
}

.adm-plain-anchor:active {
  opacity: unset;
}

body.adm-overflow-hidden {
  overflow: hidden !important;
}

div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 0.05333rem);
  width: 0;
  position: fixed;
  right: -100vw;
  bottom: -100vh;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.adm-mask {
  --z-index: var(--adm-mask-z-index, 1000);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index);
  display: block;
  width: 100%;
  height: 100%;
}

.adm-mask-aria-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.adm-mask-content {
  z-index: 1;
}

.adm-safe-area {
  --multiple: var(--adm-safe-area-multiple, 1);
  display: block;
  width: 100%;
}

.adm-safe-area-position-top {
  padding-top: calc(env(safe-area-inset-top) * var(--multiple));
}

.adm-safe-area-position-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom) * var(--multiple));
}

.adm-auto-center {
  display: flex;
  justify-content: center;
}

.adm-auto-center-content {
  flex: 0 1 auto;
}

.adm-badge-wrapper {
  display: inline-block;
  position: relative;
}

.adm-badge {
  display: inline-flex;
  vertical-align: middle;
  box-sizing: content-box;
  border-radius: 2.66667rem;
  background-color: var(--color);
  --right: 0;
  --top: 0;
  --color: var(--adm-badge-color, #ff411c);
}

.adm-badge-content {
  color: var(--adm-color-white);
  box-sizing: border-box;
  min-width: 0.21333rem;
  padding: 0.02667rem 0.10667rem;
  font-size: var(--adm-font-size-1);
  line-height: 0.32rem;
  white-space: nowrap;
  font-weight: 400;
  text-align: center;
}

.adm-badge-fixed {
  position: absolute;
  right: var(--right);
  top: var(--top);
  transform: translate(50%, -50%);
}

.adm-badge-dot {
  min-width: 0.26667rem;
  width: 0.26667rem;
  height: 0.26667rem;
  border-radius: 0.13333rem;
}

.adm-badge-bordered {
  border: solid 0.02667rem var(--adm-color-white);
}

.adm-button {
  --color: var(--adm-color-white);
  --text-color: var(--adm-button-text-color, var(--adm-color-text));
  --background-color: var(
    --adm-button-background-color,
    var(--adm-color-background)
  );
  --border-radius: var(--adm-button-border-radius, 4px);
  --border-width: var(--adm-button-border-width, 1px);
  --border-style: var(--adm-button-border-style, solid);
  --border-color: var(--adm-button-border-color, var(--adm-color-border));
  color: var(--text-color);
  background-color: var(--background-color);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  padding: 0.18667rem 0.32rem;
  margin: 0;
  font-size: var(--adm-font-size-9);
  line-height: 1.4;
  text-align: center;
  border: var(--border-width) var(--border-style) var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: opacity ease 0.15s;
  -webkit-user-select: none;
  user-select: none;
}

.adm-button:focus {
  outline: none;
}

.adm-button:before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(
    calc(var(--border-width) * -1),
    calc(var(--border-width) * -1)
  );
  width: 100%;
  height: 100%;
  background-color: #000;
  border: var(--border-width) var(--border-style) #000;
  border-radius: var(--border-radius);
  opacity: 0;
  content: " ";
  box-sizing: content-box;
}

.adm-button:active:before {
  opacity: 0.08;
}

.adm-button-default.adm-button-fill-outline {
  --background-color: transparent;
  --border-color: var(--adm-color-text);
}

.adm-button-default.adm-button-fill-none {
  --background-color: transparent;
  --border-width: 0;
}

.adm-button:not(.adm-button-default) {
  --text-color: var(--adm-color-white);
  --background-color: var(--color);
  --border-color: var(--color);
}

.adm-button:not(.adm-button-default).adm-button-fill-outline {
  --text-color: var(--color);
  --background-color: transparent;
}

.adm-button:not(.adm-button-default).adm-button-fill-none {
  --text-color: var(--color);
  --background-color: transparent;
  --border-width: 0;
}

.adm-button-primary {
  --color: var(--adm-color-primary);
}

.adm-button-success {
  --color: var(--adm-color-success);
}

.adm-button-danger {
  --color: var(--adm-color-danger);
}

.adm-button-warning {
  --color: var(--adm-color-warning);
}

.adm-button-block {
  display: block;
  width: 100%;
}

.adm-button-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.adm-button-disabled:active:before {
  display: none;
}

.adm-button.adm-button-mini {
  padding-top: 0.08rem;
  padding-bottom: 0.08rem;
  font-size: var(--adm-font-size-main);
}

.adm-button.adm-button-mini.adm-button-shape-rounded {
  padding-left: 0.24rem;
  padding-right: 0.24rem;
}

.adm-button.adm-button-small {
  padding-top: 0.08rem;
  padding-bottom: 0.08rem;
  font-size: var(--adm-font-size-7);
}

.adm-button.adm-button-large {
  padding-top: 0.29333rem;
  padding-bottom: 0.29333rem;
  font-size: var(--adm-font-size-10);
}

.adm-button.adm-button-shape-rounded {
  --border-radius: 26.66667rem;
}

.adm-button.adm-button-shape-rectangular {
  --border-radius: 0;
}

.adm-button-loading {
  vertical-align: bottom;
}

.adm-button-loading-wrapper {
  display: flex;
  height: 1.4em;
  align-items: center;
  justify-content: center;
}

.adm-button-loading-wrapper > .adm-loading {
  opacity: 0.6;
}

.adm-dot-loading {
  display: inline-block;
}

.adm-spin-loading {
  --color: var(--adm-color-weak);
  --size: 0.85333rem;
  width: var(--size);
  height: var(--size);
}

.adm-spin-loading-svg {
  width: 100%;
  height: 100%;
  animation: adm-spin-loading-rotate 0.8s infinite linear;
}

.adm-spin-loading-svg > .adm-spin-loading-fill {
  stroke: var(--color);
}

@keyframes adm-spin-loading-rotate {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.adm-list {
  --header-font-size: var(--adm-font-size-7);
  --prefix-width: "auto";
  --prefix-padding-right: 0.32rem;
  --align-items: center;
  --active-background-color: var(--adm-color-border);
  --border-inner: solid 0.02667rem var(--adm-color-border);
  --border-top: solid 0.02667rem var(--adm-color-border);
  --border-bottom: solid 0.02667rem var(--adm-color-border);
  --padding-left: 0.32rem;
  --padding-right: 0.32rem;
  --font-size: var(--adm-font-size-9);
  --extra-max-width: 70%;
}

.adm-list-header {
  color: var(--adm-color-weak);
  font-size: var(--header-font-size);
  padding: 0.21333rem var(--padding-right) 0.21333rem var(--padding-left);
}

.adm-list-body {
  overflow: hidden;
  font-size: var(--font-size);
}

.adm-list-body-inner {
  margin-top: -0.02667rem;
  padding: 15px 0;
}

.adm-list-default .adm-list-body {
  border: none;
}

.adm-list-card {
  margin: 0.32rem;
}

.adm-list-card .adm-list-body {
  border-radius: 0.21333rem;
}

.adm-list-card .adm-list-header {
  padding-left: 0;
}

.adm-list-item {
  display: block;
  padding-left: var(--padding-left);
  padding-right: var(--padding-left);
  position: relative;
  line-height: 1.5;

  &:first-child {
    .adm-list-item-content {
      border-top: none;
    }
  }
}

.adm-list-item-title,
.adm-list-item-description {
  color: var(--adm-color-weak);
  font-size: var(--adm-font-size-main);
}

.adm-list-item-content {
  display: flex;
  flex-direction: column;
  align-items: var(--align-items);
  justify-content: flex-start;
  border-top: var(--border-inner);
  padding-right: var(--padding-right);
}

.adm-list-item-content-prefix {
  width: var(--prefix-width);
  flex: none;
  padding-right: var(--prefix-padding-right);
}

.adm-list-item-content-main {
  flex: auto;
  padding: 0.32rem 0;
}

.adm-list-item-content-extra {
  flex: none;
  padding-left: 0.32rem;
  font-size: var(--adm-font-size-7);
  color: var(--adm-color-weak);
  max-width: var(--extra-max-width);
}

.adm-list-item-content-arrow {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: 0.10667rem;
  color: var(--adm-color-light);
  font-size: 0.50667rem;
}

.adm-list-item-disabled {
  cursor: not-allowed;
}

.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
  opacity: 0.4;
  pointer-events: none;
}

a.adm-list-item:active:not(.adm-list-item-disabled) {
  background-color: var(--active-background-color);
}

a.adm-list-item:active:not(.adm-list-item-disabled):after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  bottom: -0.02667rem;
  left: 0;
  border-bottom: var(--border-inner);
}

.adm-error-block {
  --color: var(--adm-color-text);
  --image-height: var(--adm-error-block-image-height, 100px);
  --image-height-full-page: var(
    --adm-error-block-image-height-full-page,
    200px
  );
  --image-width: var(--adm-error-block-image-width, auto);
  --image-width-full-page: var(--adm-error-block-image-width-full-page, auto);
  box-sizing: border-box;
  text-align: center;
}

.adm-error-block-image {
  height: var(--image-height);
  width: var(--image-width);
  max-width: 100%;
}

.adm-error-block-image svg,
.adm-error-block-image img {
  height: 100%;
}

.adm-error-block-description {
  font-size: var(--adm-font-size-4);
  color: #999;
  line-height: 1.4;
  margin-top: 0.32rem;
}

.adm-error-block-description-title {
  font-size: var(--adm-font-size-7);
}

.adm-error-block-description-subtitle {
  margin-top: 0.21333rem;
}

.adm-error-block-content {
  margin-top: 0.32rem;
}

.adm-error-block-full-page {
  padding-top: calc(50vh - var(--image-height-full-page));
}

.adm-error-block-full-page .adm-error-block-image {
  height: var(--image-height-full-page);
  width: var(--image-width-full-page);
}

.adm-error-block-full-page .adm-error-block-description {
  margin-top: 0.53333rem;
  font-size: var(--adm-font-size-main);
}

.adm-error-block-full-page .adm-error-block-description-title {
  font-size: 0.53333rem;
  color: var(--adm-color-text);
}

.adm-form {
  --border-inner: solid 0.02667rem var(--adm-border-color);
  --border-top: solid 0.02667rem var(--adm-border-color);
  --border-bottom: solid 0.02667rem var(--adm-border-color);
  --prefix-width: 6.8em;
  ---border-inner: var(--border-inner);
  ---border-top: var(--border-top);
  ---border-bottom: var(--border-bottom);
  ---prefix-width: var(--prefix-width);
}

.adm-form .adm-list.adm-list {
  --padding-left: 0.42667rem;
  --padding-right: 0.32rem;
  --border-inner: var(---border-inner);
  --border-top: var(---border-top);
  --border-bottom: var(---border-bottom);
}

.adm-form .adm-form-footer {
  padding: 0.53333rem 0.32rem;
}

.adm-form .adm-form-item-horizontal.adm-list-item {
  --prefix-width: var(---prefix-width);
}

.adm-form-list-operation {
  text-align: center;
  color: #1677ff;
}

.adm-form-item + .adm-form-item {
  border-top: none;
}

.adm-form-item-label {
  display: block;
  height: 100%;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
  color: var(--adm-color-text-secondary);

  .btn-all {
    color: rgb(0, 122, 255);
    margin-left: 5px;
  }
}

.adm-form-item-label .adm-form-item-required-asterisk {
  position: absolute;
  left: -0.6em;
  top: 0;
  font-family: SimSun, sans-serif;
  color: var(--adm-color-danger);
  -webkit-user-select: none;
  user-select: none;
}

.adm-form-item-label .adm-form-item-required-text {
  margin-left: 0.10667rem;
  color: var(--adm-color-weak);
}

.adm-form-item-label-help {
  margin-left: 0.10667rem;
  cursor: pointer;
}

.adm-form-item-child {
  display: flex;
}

.adm-form-item-child-position-normal {
  justify-content: normal;
}

.adm-form-item-child-position-normal > * {
  flex: auto;
}

.adm-form-item-child-position-right {
  justify-content: flex-end;
}

.adm-form-item-child-position-right > * {
  flex: none;
}

.adm-form-item-feedback-error {
  color: var(--adm-color-danger);
  margin-top: 0.10667rem;
}

.adm-form-item-feedback-warning {
  color: var(--adm-color-warning);
  margin-top: 0.10667rem;
}

.adm-form-item.adm-form-item-hidden {
  display: none;
}

.adm-form-item.adm-form-item-horizontal.adm-list-item {
  --align-items: stretch;
}

.adm-form-item.adm-form-item-horizontal .adm-list-item-content-prefix {
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
}

.adm-form-item.adm-form-item-horizontal .adm-list-item-content-extra {
  align-self: center;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
  font-size: var(--adm-font-size-7);
  margin-bottom: 0.10667rem;
}

.adm-popover {
  --z-index: var(--adm-popover-z-index, 1030);
  --background: #ffffff;
  --arrow-size: 0.21333rem;
  --content-padding: 0.21333rem 0.32rem;
  color: #333;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index);
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  user-select: text;
  animation: none;
}

.adm-popover.adm-popover-dark {
  --background: rgba(0, 0, 0, 0.75);
  --adm-color-text: #ffffff;
  color: #fff;
}

.adm-popover.adm-popover-dark .adm-popover-inner {
  box-shadow: none;
}

.adm-popover:after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: "";
}

.adm-popover-hidden {
  display: none;
}

.adm-popover-inner {
  background-color: var(--background);
  background-clip: padding-box;
  border-radius: 0.21333rem;
  box-shadow: 0 0 0.8rem #3333;
  font-size: var(--adm-font-size-7);
  width: -webkit-max-content;
  width: max-content;
  min-width: 0.85333rem;
  max-width: calc(100vw - 0.64rem);
  overflow-y: hidden;
}

.adm-popover-inner-content {
  padding: var(--content-padding);
}

.adm-popover-arrow {
  position: absolute;
  display: block;
  height: var(--arrow-size);
  width: var(--arrow-size);
  overflow: visible;
  background: transparent;
}

.adm-popover-arrow-icon {
  display: block;
  height: var(--arrow-size);
  width: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(var(--arrow-icon-rotate));
}

.adm-popover .adm-popover-arrow {
  color: var(--background);
}

.adm-popover-menu {
  --border-color: #eeeeee;
}

.adm-popover-menu.adm-popover {
  --content-padding: 0;
}

.adm-popover-menu-list {
  overflow: hidden;
  min-width: 3.2rem;
}

.adm-popover-menu-list-inner {
  margin-top: -0.02667rem;
}

.adm-popover-menu-item {
  display: flex;
  padding-left: 0.53333rem;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.adm-popover-menu-item-icon {
  flex: none;
  padding-right: 0.21333rem;
  font-size: 0.53333rem;
}

.adm-popover-menu-item-text {
  flex: auto;
  padding: 0.37333rem 0.53333rem 0.37333rem 0;
  border-top: solid 0.02667rem var(--border-color);
}

.adm-popover-menu-item-disabled {
  cursor: not-allowed;
}

.adm-popover-menu-item-disabled > * {
  opacity: 0.4;
}

.adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled) {
  background-color: var(--border-color);
}

.adm-popover-menu-item:active:not(.adm-popover-menu-item-disabled):after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  bottom: -0.02667rem;
  left: 0;
  border-bottom: solid 0.02667rem var(--border-color);
}

.adm-popover.adm-popover-dark.adm-popover-menu {
  --border-color: #333333;
  --background: rgba(0, 0, 0, 0.9);
}

.adm-input {
  --font-size: var(--adm-font-size-9);
  --color: var(--adm-color-text);
  --placeholder-color: var(--adm-color-light);
  --text-align: left;
  --background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 0.64rem;
  background-color: var(--background-color);
}

.adm-input-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.adm-input-element {
  flex: auto;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  color: #333;
  font-size: var(--font-size);
  line-height: 1.5;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  min-height: 1.5em;
  text-align: var(--text-align);
}

.adm-input-element::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-input-element::placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}

.adm-input-element:-webkit-autofill {
  background-color: transparent;
}

.adm-input-element:read-only {
  cursor: default;
}

.adm-input-element:invalid {
  box-shadow: none;
}

.adm-input-element::-ms-clear {
  display: none;
}

.adm-input-element::-ms-reveal {
  display: none;
}

.adm-input-element::-webkit-search-cancel-button {
  display: none;
}

.adm-input-element::-webkit-search-decoration {
  display: none;
}

.adm-input-element:disabled {
  opacity: 1;
}

.adm-input-element[type="date"],
.adm-input-element[type="time"],
.adm-input-element[type="datetime-local"] {
  min-height: 1.5em;
}

.adm-input-element[type="search"] {
  -webkit-appearance: none;
}

.adm-input-element[readonly] {
  pointer-events: none;
}

.adm-input-clear {
  flex: none;
  margin-left: 0.21333rem;
  color: var(--adm-color-light);
  padding: 0.10667rem;
  cursor: pointer;
}

.adm-input-clear:active {
  color: var(--adm-color-weak);
}

.adm-input-clear .antd-mobile-icon {
  display: block;
  font-size: var(--adm-font-size-7);
}

.adm-nav-bar {
  --height: 1.2rem;
  --border-bottom: none;
  display: flex;
  align-items: center;
  height: var(--height);
  border-bottom: var(--border-bottom);
  padding: 0 0.32rem;
  white-space: nowrap;
}

.adm-nav-bar-left,
.adm-nav-bar-right {
  flex: 1;
}

.adm-nav-bar-title {
  flex: auto;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adm-nav-bar-back {
  display: flex;
  align-items: center;
  margin-right: 0.42667rem;
  padding: 0.16rem 0;
  cursor: pointer;
}

.adm-nav-bar-back-arrow {
  font-size: 0.64rem;
  margin-right: 0.10667rem;
}

.adm-nav-bar-left {
  font-size: var(--adm-font-size-7);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.adm-nav-bar-title {
  justify-content: center;
  white-space: nowrap;
  font-size: var(--adm-font-size-10);
  padding: 0 0.32rem;
}

.adm-nav-bar-right {
  text-align: right;
}

.adm-notice-bar {
  --background-color: #ababab;
  --border-color: #999999;
  --text-color: var(--adm-color-white);
  --font-size: var(--adm-font-size-7);
  --icon-font-size: var(--adm-font-size-10);
  --height: 30px;
  height: var(--height);
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  color: var(--text-color);
  width: 90%;
  margin: 15px auto;
  border-radius: 30px;

  img {
    width: 20px;
    height: auto;
  }
}

.adm-notice-bar > span[role="img"] {
  color: var(--text-color);
}

.adm-notice-bar.adm-notice-bar-alert {
  --background-color: #e0e0e0;
  --text-color: #333;
}

.adm-notice-bar.adm-notice-bar-error {
  --background-color: #ff3b30;
  --border-color: #d9281e;
  --text-color: #ffffff;
}

.adm-notice-bar.adm-notice-bar-info {
  --background-color: #d0e4ff;
  --border-color: #bcd8ff;
  --text-color: #1677ff;
}

.adm-notice-bar .adm-notice-bar-left {
  flex-shrink: 0;
  margin-right: 0.21333rem;
  font-size: var(--icon-font-size);
}

.adm-notice-bar .adm-notice-bar-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.adm-notice-bar .adm-notice-bar-content .adm-notice-bar-content-inner {
  width: auto;
  transition-timing-function: linear;
  white-space: nowrap;
}

.adm-notice-bar-wrap.adm-notice-bar
  .adm-notice-bar-content
  .adm-notice-bar-content-inner {
  white-space: normal;
}

.adm-notice-bar .adm-notice-bar-right {
  flex-shrink: 0;
  margin-left: 0.32rem;
}

.adm-notice-bar-close {
  width: 0.64rem;
  height: 0.64rem;
  margin-right: -0.08rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adm-notice-bar-close-icon {
  font-size: var(--adm-font-size-10);
}

.adm-notice-bar-wrap {
  height: auto;
  align-items: flex-start;
  padding-top: 0.21333rem;
  padding-bottom: 0.21333rem;
  line-height: 0.58667rem;
}

.adm-page-indicator {
  display: flex;
  width: auto;
  --dot-color: #dddddd;
  --active-dot-color: var(--adm-color-primary);
  --dot-size: 0.08rem;
  --active-dot-size: 0.34667rem;
  --dot-border-radius: 0.02667rem;
  --active-dot-border-radius: var(--dot-border-radius);
  --dot-spacing: 0.08rem;
}

.adm-page-indicator-dot {
  display: block;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--dot-border-radius);
  background: var(--dot-color);
}

.adm-page-indicator-dot:last-child {
  margin-right: 0;
}

.adm-page-indicator-dot-active {
  border-radius: var(--active-dot-border-radius);
  background: var(--active-dot-color);
}

.adm-page-indicator-color-white {
  --dot-color: rgba(255, 255, 255, 0.5);
  --active-dot-color: var(--adm-color-white);
}

.adm-page-indicator-horizontal {
  flex-direction: row;
}

.adm-page-indicator-horizontal .adm-page-indicator-dot {
  margin-right: var(--dot-spacing);
}

.adm-page-indicator-horizontal .adm-page-indicator-dot-active {
  width: var(--active-dot-size);
}

.adm-page-indicator-vertical {
  flex-direction: column;
}

.adm-page-indicator-vertical .adm-page-indicator-dot {
  margin-bottom: var(--dot-spacing);
}

.adm-page-indicator-vertical .adm-page-indicator-dot-active {
  height: var(--active-dot-size);
}

.adm-swiper {
  --height: auto;
  --width: 100%;
  --border-radius: 0;
  --track-padding: 0;
  --slide-size: 100%;
  --track-offset: 0%;
  display: block;
  width: var(--width);
  height: var(--height);
  position: relative;
  border-radius: var(--border-radius);
  z-index: 0;
  overflow: hidden;
}

.adm-swiper-track {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  padding: var(--track-padding);
}

.adm-swiper-track-allow-touch-move {
  cursor: -webkit-grab;
  cursor: grab;
}

.adm-swiper-track-inner {
  width: 100%;
  height: 100%;
  overflow: visible;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}

.adm-swiper-slide {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  white-space: unset;
  flex: none;
}

.adm-swiper-item {
  display: block;
  width: 100%;
  height: 100%;
  white-space: normal;
}

.adm-swiper-horizontal .adm-swiper-track-allow-touch-move {
  touch-action: pan-y;
}

.adm-swiper-horizontal .adm-swiper-indicator {
  position: absolute;
  bottom: 0.16rem;
  left: 50%;
  transform: translate(-50%);
}

.adm-swiper-horizontal .adm-swiper-track {
  transform: translate(var(--track-offset));
}

.adm-swiper-horizontal .adm-swiper-track-inner {
  flex-direction: row;
  width: var(--slide-size);
}

.adm-swiper-vertical .adm-swiper-track-allow-touch-move {
  touch-action: pan-x;
}

.adm-swiper-vertical .adm-swiper-indicator {
  position: absolute;
  right: 0.16rem;
  top: 50%;
  transform: translateY(-50%);
}

.adm-swiper-vertical .adm-swiper-track {
  transform: translateY(var(--track-offset));
}

.adm-swiper-vertical .adm-swiper-track-inner {
  flex-direction: column;
  height: var(--slide-size);
}

.adm-tab-bar-wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  min-height: 1.28rem;
}

.adm-tab-bar-item {
  flex: 1;
  color: var(--adm-color-text-secondary);
  white-space: nowrap;
  padding: 0.10667rem 0.21333rem;
  width: -webkit-min-content;
  width: min-content;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.adm-tab-bar-item-icon {
  font-size: 0.64rem;
  height: 0.64rem;
  line-height: 1;
}

.adm-tab-bar-item-title {
  font-size: var(--adm-font-size-2);
  line-height: 0.4rem;
}

.adm-tab-bar-item-title-with-icon {
  margin-top: 0.05333rem;
}

.adm-tab-bar-item-active {
  color: var(--adm-color-primary);
}

.adm-tab-bar-icon-badge {
  --top: 0.16rem;
}

.adm-tab-bar-title-badge {
  --right: -0.05333rem;
  --top: -0.05333rem;
}

.adm-toast-mask .adm-toast-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.adm-toast-mask .adm-toast-main {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 5.44rem;
  max-height: 70%;
  overflow: auto;
  color: #fff;
  word-break: break-all;
  background-color: #000000b3;
  border-radius: 0.21333rem;
  pointer-events: all;
  font-size: var(--adm-font-size-7);
  line-height: 1.5;
  box-sizing: border-box;
}

.adm-toast-mask .adm-toast-main-text {
  padding: 0.32rem;
  min-width: 0;
}

.adm-toast-mask .adm-toast-main-icon {
  padding: 0.93333rem 0.32rem;
  min-width: 4rem;
}

.adm-toast-mask .adm-toast-main-icon .adm-toast-icon {
  text-align: center;
  margin-bottom: 0.21333rem;
  font-size: 0.96rem;
  line-height: 1;
}

.adm-toast-loading {
  --size: 1.28rem;
  margin: 0 auto 0.21333rem;
}

#root {
  height: 100%;
  overflow-y: auto;
}

.refresh-text {
  font-size: 0.4rem;
}

.exit-dialog .adm-dialog-content {
  text-align: center;
}

.exit-dialog .adm-dialog-action-row .adm-button:nth-of-type(1) {
  color: #999;
}

.exit-dialog .adm-dialog-action-row .adm-button:nth-of-type(2) {
  color: red;
}

.toast-show-class .adm-toast-main {
  max-width: 6.4rem !important;
}

.not-found-page .go-home {
  width: 3.73333rem;
  font-size: 0.4rem;
  margin: 0.8rem auto 0;
  display: block;
  text-align: center;
  border: 0.02667rem solid #1677ff;
  padding: 0.26667rem;
  text-decoration: none;
}

.tab-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tab-layout .layout-navbar {
  background-color: #1273f6;
  --height: 1.17333rem;
  color: #fff;
}

.tab-layout .page-container {
  flex: 1;
  overflow-y: auto;
}

.tab-layout .layout-tabbar {
  border-top: 0.02667rem solid #f4f4f4;
}

.home-page {
  min-height: 100%;
}

.home-page .banner {
  padding: 0.13333rem 0;
}

.home-page .banner .banner-item {
  height: 4.13333rem;
  margin: 0 0.13333rem;
  border-radius: 0.26667rem;
  overflow: hidden;
}

.home-page .banner .banner-item img {
  width: 100%;
  height: 100%;
}

.home-page .statistics {
  width: 95%;
  box-sizing: border-box;
  border: solid 0.05333rem #efefef;
  border-radius: 0.21333rem;
  margin: 0.26667rem auto;
  display: flex;
  padding: 0.21333rem 0.21333rem 0.21333rem 0;
}

.home-page .statistics .left {
  flex: 1;
}

.home-page .statistics .left > div {
  display: flex;
  align-items: center;
  padding: 0.10667rem 0;
}

.home-page .statistics .left > div i {
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
  background: white URL("../img/home_money_icon.png") no-repeat center;
  background-size: 100% 100%;
}

.home-page .statistics .left > div span {
  font-size: 0.42667rem;
}

.home-page .statistics .left > div span em {
  margin: 0 0.13333rem;
}

.home-page .statistics .left .user-count i {
  background-image: URL("../img/home_register_icon.png");
}

.home-page .statistics .right {
  width: 21%;
  flex-shrink: 0;
}

.home-page .statistics .right .value {
  font-size: 0.4rem;
}

.home-page .statistics .right .value span {
  font-size: 0.8rem;
  font-style: inherit;
  font-weight: 700;
  color: red;
}

.home-page .statistics .right .label {
  font-size: 0.42667rem;
}

.home-page .vip-desc {
  width: 95%;
  margin: 0.26667rem auto;
  font-size: 0;
  height: 1.01333rem;
}

.home-page .vip-desc a {
  display: block;
  width: 100%;
  height: 100%;
}

.home-page .vip-desc img {
  width: 100%;
  height: 100%;
}

.home-page .room-entry {
  width: 95%;
  height: 4.53333rem;
  margin: 0.26667rem auto;
}

.home-page .room-entry a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.10667rem;
  overflow: hidden;
}

.home-page .room-entry img {
  width: 100%;
  height: 100%;
}

.home-page .list-wrap {
  width: 95%;
  padding: 0.26667rem 0;
  margin: 0 auto;
}

.home-page .winner-list-wrap {
  overflow: hidden;
}

.home-page .winner-list-wrap .title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: var(--theme-color);
  z-index: 99;

  .text {
    border-left: 3px solid #ffffff;
    font-size: 13px;
    line-height: 13px;
    padding-left: 5px;
  }
}

.home-page .winner-list-wrap .winner-list {
  height: 16rem;
  overflow-y: hidden;
}

.home-page .winner-list-wrap .winner-list .winner-item {
  height: 30px;
  box-sizing: border-box;
  padding: 0 0.21333rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;

  &.head {
    position: relative;
    z-index: 99;
    background-color: #ececec;
    border: none;
    height: 30px;

    span {
      color: #333;
    }
  }

  span {
    text-align: center;
    width: 25%;
  }

  .blue {
    color: var(--theme-color);
  }
}

.home-page .winner-list-wrap .winner-list .winner-item .left {
  font-size: 0.32rem;
  color: #999;
}

.home-page .winner-list-wrap .winner-list .winner-item .left .name {
  font-size: 0.4rem;
  color: #333;
  font-weight: 700;
}

.home-page .winner-list-wrap .winner-list .winner-item .right span {
  color: red;
}

.login-page {
  height: 100%;
}

.login-page .login-bg {
  width: 100%;
  height: 5.33333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .login-bg .logo {
  width: 2.13333rem;
  height: 2.13333rem;
  border-radius: 0.26667rem;
  overflow: hidden;
}

.login-page .login-bg .company-info {
  margin-left: 0.53333rem;
}

.login-page .login-bg .company-info .name {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
}

.login-page .login-bg .company-info .desc {
  font-size: 0.4rem;
  text-align: center;
  line-height: 1;
}

.login-page .form-wrap {
  width: 90%;
  margin: 0.8rem auto;
  box-shadow: 0 0.05333rem 0.10667rem #0000;
}

.login-page .form-wrap .form-submit-btn {
  margin-top: 0.53333rem;
}

.login-page .form-wrap .go-link {
  font-size: 0.34667rem;
  margin: 0.53333rem auto;
  text-align: center;
}

.login-page .form-wrap .go-link a {
  text-decoration: none;
}

.login-page .form-wrap .adm-list-item-content-prefix {
  width: 0.88rem;
}

.login-page .form-wrap .adm-list-item-content-prefix svg {
  font-size: 0.58667rem;
}

.page-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-loading .icon {
  font-size: 2.13333rem;
  color: #1273f6;
}

.page-loading .adm-loading {
  color: #1273f6 !important;
}

.page-loading .img-wrap {
  width: 2.66667rem;
  height: 2.66667rem;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
}
