#root {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  opacity: 0;
}

::-webkit-scrollbar-track {
  background: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

a {
  text-decoration: none !important;
}

a,
button {
  outline: 0 !important;
  position: relative;
}

img,
svg {
  vertical-align: middle;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

iframe {
  border: 0;
}

.error_t {
  font-size: 13px;
}

.remix-auth-page {
  width: 100%;
  height: 100%;
  max-width: 540px;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;

  .bg-wrapper {
    color: #000;
    height: 100vh;
  }

  .logo-img {
    width: 100px;
    max-width: 400px;
    height: auto;
    // border: #eee 2px solid;
  }

  .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;
    height: 50%;

    h2 {
      margin-top: 20px;
    }
  }

  .auth-form-user {
    width: 100%;
    max-width: 90%;
    margin-top: 40px;

    ul {
      padding: 0;

      li {
        padding: 0;
        margin-bottom: 20px;
        list-style: none;
      }
    }

    .field-group {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dadada;
      color: #666;

      svg {
        font-size: 16px;
      }
    }

    .field_control {
      height: 45px;
      font-size: 16px;
      background-color: transparent;
      color: #666;
    }

    .control-wrapper {
      height: 45px;
      text-align: center;
      // border-radius: 10px;
      background-color: transparent;
      width: 100%;
      position: relative;

      .field_control {
        background: transparent;
        border: none;
        color: #666;
      }

      input:-internal-autofill-selected {
        background-color: transparent;
      }

      svg {
        top: 13px;
        padding: 0;
        color: #666;
      }
    }

    .error {
      .control-wrapper {
        .field_control {
          border: none;
        }
      }
    }

    .error_t {
      color: #dd2100;
      margin-top: 5px;
      display: block;
    }
  }

  .login-footer {
    margin-top: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-support {
      font-size: 18px;
      color: #ff0011;
    }

    .lang {
      display: flex;
      align-items: center;
      gap: 5px;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        color: #333;
        font-size: 18px;
      }
    }
  }

  .forgot-pass {
    display: flex;
    justify-content: flex-end;
    margin: 15px 0;
    font-size: 16px;
  }

  .signin-other-title {
    text-align: right;
    font-size: 18px;
    margin-top: 10px;
    color: var(--adm-color-primary);

    a {
      color: var(--adm-color-primary);
    }
  }

  .btn-submit {
    color: #fff;
    background-color: var(--theme-color);
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    padding: 5px 11px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
}

.bg_header {
  position: fixed;
  left: 0;
  top: 0;
  height: 44px;
  padding: 7px 3px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 998;
  color: #fff;
  background-color: var(--theme-color);
  border-bottom: solid 1px #f1f1f1;
  transition-property: all;
  width: 100%;

  .btn_back {
    position: absolute;
    left: 15px;
    top: 10px;
    font-size: 18px;
  }

  .bg_header_name {
    position: absolute;
    left: 70px;
    right: 70px;
    min-width: 0;
    user-select: auto;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 1;
  }
}

.home-top-bar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  max-width: 550px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 999;
  padding: 15px;
  background: var(--theme-color);
  margin: 0;

  .bg_header_name {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
  }

  .logo-div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .logo-img {
    width: 115px;
    object-fit: contain;
  }

  .btn_back {
    position: absolute;
    z-index: 9;
    left: 8px;
    top: 0;
    line-height: 25px;
    cursor: pointer;
  }

  .btn_save {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 12px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    padding: 4px 11px;
  }

  .right {
    position: absolute;
    z-index: 9;
    right: 15px;
    top: 12px;
    font-size: 18px;
    color: #ffffff;
  }

  svg {
    color: #ffffff;
    font-size: 18px;
  }
}

.mobile-layout {
  width: 100%;
  height: 100%;
  max-width: 540px;
  margin: 0 auto;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 60px;
  overflow: hidden;
  overflow-y: auto;
}

.footer-mobile-top {
  position: fixed;
  z-index: 999;
  left: 50%;
  width: 100%;
  max-width: 540px;
  bottom: 47px;
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
  transform: translateX(-50%);
  border-top: 1px solid rgba(184, 184, 184, 0.53);
  color: #7f72a8;
  font-size: 15px;
  background: #ffffff;

  .data__balance {
    color: var(--theme-color);
  }
}

.footer-mobile {
  position: fixed;
  z-index: 999;
  background: #ffffff;
  color: #333;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 540px;
  height: 50px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px -2px 2px rgba(171, 171, 171, 0.63);

  .footer_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    color: rgb(156, 154, 156);
    text-transform: uppercase;
    min-width: 50px;

    &.main-img {
      top: -0.26667rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-mark {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 73px;
      }
    }

    &.active {
      color: var(--theme-color);

      svg {
        color: var(--theme-color);
      }

      img {
        filter: brightness(0) saturate(100%) invert(59%) sepia(68%)
          saturate(3775%) hue-rotate(1deg) brightness(103%) contrast(103%);
      }
    }

    svg {
      width: 0.64rem;
      height: 0.64rem;
      font-size: 0.64rem;
      color: var(--adm-color-text-secondary);
    }

    h5 {
      font-size: 12px;
      line-height: 0.4rem;
      margin-top: 0.05333rem;
      font-weight: 400;
      text-align: center;
      text-transform: none;
    }

    img {
      width: 24px;
      height: auto;
    }
  }
}

.grid {
  display: flex;
  justify-content: space-around;
  padding: 5px;

  .grid__type_l {
    font-size: 12px;
    color: #fff;
    margin-right: 5px;

    .grid__type_l_img {
      width: 225px;
      height: 75px;
      display: block;
      background: var(--theme-color);
      color: #ffffff;
      border-radius: 10px 8px 15px 50px;
      padding: 5px 25px;

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 16px;
      }

      img {
        width: 100%;
      }
    }
  }

  svg {
    font-size: 18px;
    color: #ffffff;
    margin: 3px 5px 0;
  }

  .grid__type_btn {
    background-color: var(--theme-color);
    padding: 2px 5px;
    margin-bottom: 5px;
    height: 35px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }

  .grid__title_fr {
    font-size: 12px;
    color: #fff;
  }
}

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  .loading-img {
    width: 120px;
  }
}

.btn {
  box-shadow: none;
  border: none;
  outline: 0;
  text-align: center;
  background-color: var(--theme-color);
  color: #ffffff;
  width: 100%;
  padding: 8px 11px;
  font-size: var(--adm-font-size-9);
  line-height: 1.4;
  min-height: 36px;
  border-radius: 4px;

  &:hover,
  &:focus {
    box-shadow: none;
    border: none;
    outline: 0;
    opacity: 0.8;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
}

.btn-more {
  margin: 0 auto;
  margin-top: 15px;
  font-size: 17px;
  font-weight: 600;
}

.form-action {
  background-color: #ffffff;
  padding: 0 20px;

  .form-item {
    padding: 15px 0;

    .form_label {
      flex: none;
      box-sizing: border-box;
      text-align: left;
      word-wrap: break-word;
      margin-right: 15px;
      font-size: 18px;
    }

    .form_field {
      width: 100%;
      border-bottom: 1px solid #969799;
    }

    &.error {
      .error_t {
        display: block;
        color: #f55f5f;
        margin-top: 5px;
        font-size: 13px;
      }
    }
  }

  label {
    display: flex;
    align-items: center;

    span {
      color: #363636;
      font-size: 18px;
      margin-left: 10px;
    }
  }
}

.setting-container {
  padding-top: 45px;
}

.form-horizontal {
  .form-item {
    display: flex;
  }
}

.field_control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 4px 10px;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  color: #666;
  height: 36px;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
}

.control-number {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
  height: 36px;
}

.field_select {
  display: block;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  height: 36px;
  padding: 4px 10px;
  color: #363636;
  line-height: inherit;
  text-align: left;
  background-color: #eaeff2;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 14px;
  border-radius: 7px;
}

.control-wrapper {
  display: flex;
  align-items: center;

  svg {
    color: #ffffff;
    font-size: 18px;
    margin-right: 6px;
  }
}

.bank-img {
  width: 100%;
  margin-top: 5%;
}

.text-red {
  color: #dd2100 !important;
}

.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;

  .mask {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.603);
  }

  &.modal-full {
    .modal-content {
      top: 0;
      width: 100%;
      height: 100vh;
    }

    .content {
      padding: 0 20px;

      p {
        text-align: left;

        b {
          color: #000;
        }
      }

      ul {
        padding-left: 15px;

        li {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          list-style: disc;
          margin-bottom: 10px;
        }
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #eeeeee;
    padding: 10px 15px;
  }

  .modal-content {
    background-color: #ffffff;
    position: absolute;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
    top: 10vh;
    width: 80%;
    -webkit-box-shadow: 1px 1px 10px 0px rgba(171, 171, 171, 0.63);
    -moz-box-shadow: 1px 1px 10px 0px rgba(171, 171, 171, 0.63);
    box-shadow: 1px 1px 10px 0px rgba(171, 171, 171, 0.63);
  }

  .modal-bottom {
    left: 0;
    bottom: 0;
    top: initial;
    width: 100%;
    transform: translateX(0);
    border-radius: 15px 15px 0 0;
  }
}

.modal-bet-game {
  .modal-content {
    top: initial;
    bottom: 0;
    left: 50%;
    max-height: 375px;
    transform: translateX(-50%);
    max-height: 450px;
    width: 100%;
    max-width: 540px;
    background: #6590ee;
    color: #ffffff;
    box-shadow: none;
    border-radius: 0;
    font-size: 18px;
    padding: 25px 15px;
  }

  .bet-items {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 15px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;

    &.double {
      .bet-item {
        width: calc(100% / 3 - 10px);
      }
    }

    .bet-item {
      width: calc(50% - 10px);
      height: 40px;
      border-radius: 10px;
      background-color: #ffffff;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #ff0011;
      }

      &.active {
        background-color: #ca2d38;
        color: #ffffff;

        span {
          color: #ffffff;
        }
      }
    }
  }

  .btn-action {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    gap: 10px;

    .input-bet {
      height: 40px;
      border-radius: 10px;
      border: 1px solid #dcdcdc;
      color: #222428;
      font-size: 18px;
      padding: 4px 11px;
      flex: 1;
    }

    span {
      width: 200px;
    }

    .btn-bet {
      border: none;
      box-shadow: none;
      outline: 0;
      color: #ffffff;
      background-color: #eca540;
      font-size: 16px;
      font-weight: 600;
      width: 150px;
      height: 40px;
      padding: 4px 10px;
      border-radius: 5px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    a {
      height: 35px;
      padding: 4px 10px;
      border: 1px solid #ffffff;
      color: #ffffff;
      border-radius: 10px;
      line-height: 25px;
    }
  }

  &.modal-gift {
    .modal-content {
      background-color: #ca2d38;
      background-image: URL("../img/gift-box.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 60vh;
      max-width: 380px;

      .title {
        h5 {
          background: transparent;
          border: none;
          color: #ffee00;
          font-size: 32px;
          text-shadow: 1px 2px #363636;
        }
      }

      .content {
        border: none;

        p {
          color: #ffffff !important;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .tab {
    width: 180px;
    height: 40px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;

    &.active {
      color: var(--theme-color);
      border-bottom: 3px solid var(--theme-color);
    }
  }
}

.container_main {
  padding-top: 45px;
}

.classic-bet-now {
  .title {
    padding: 20px;

    h5 {
      padding: 8px 0;
      background-color: #fff;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      border: 1px solid #e5e5e5;
      text-align: center;
    }

    h4 {
      padding: 8px 0;
      color: #000;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
    }
  }

  .content {
    border-top: 1px solid rgb(236, 236, 236);
    border-bottom: 1px solid rgb(236, 236, 236);
    padding: 5px 0px;
    max-height: 45vh;
    overflow: auto;

    p {
      color: #999;
      margin: 5px 0 0;
      font-family: Roboto, helvetica neue, Arial, sans-serif;
      text-align: center;
      line-height: 1.5;
      font-size: 16px;
    }
  }

  .submit {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;

    .btn-submit {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      line-height: 29px;
      border-radius: 5px;
      font-size: 14px;
    }

    .btn-cancel {
      color: #fff;
      background: gray;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 50px;
      line-height: 29px;
      border-radius: 5px;
      font-size: 14px;
    }
  }
}

.collapses {
  .collapse-item {
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 18px;

    .collapse-title {
      padding: 15px 20px;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--theme-color);

      .van-icon {
        font-size: 20px;
        color: #979797;
      }
    }

    .collapse-wrapper {
      padding: 15px 20px;
      transition: height 0.2s ease-in-out;
      will-change: height;

      .collapse-body {
        padding-left: 20px;
        color: var(--theme-color);

        p {
          color: var(--theme-color);
        }
      }
    }
  }
}

.nodata {
  .noMSG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #969799;

    img {
      max-width: 195px;
      width: 100%;
    }
  }
}

.line {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.theme-color {
  color: var(--theme-color) !important;
}

.tradingview-widget-container,
#crypt-candle-chart {
  height: 96%;
  min-height: 500px;
}

#crypt-candle-chart > div > iframe {
  min-height: 500px;
}

#tradingview_24be9-wrapper {
  position: relative;
  box-sizing: content-box;
  font-family: -apple-system, BlinkMacSystemFont, "Trebuchet MS", Roboto, Ubuntu,
    sans-serif;
  margin: 0px auto !important;
  padding: 0px !important;
  width: 100%;
}

.language-page {
  padding-top: 65px;
  padding-bottom: 50px;
  .lang-list {
    padding: 0 20px;
    .item {
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      margin-bottom: 10px;
      text-align: center;
      color: #333;
      background-image: linear-gradient(#fff, #e8e8e8);
      border: solid 1px #e5e5e5;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      &.active {
        color: #ffffff;
        background-image: linear-gradient(#ffb443, #ff7b00);
      }
    }
  }
}

.help-text {
  font-size: 14px;
  padding: 60px 16px;
  h5 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  ul {
    li {
      margin-bottom: 10px;
    }
  }
}
