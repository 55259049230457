.page-content-support {
  margin: 0px;
  padding-bottom: 0px;
  overflow: hidden;
  height: calc(100vh - 65px);
}

.member-footer {
  margin-top: 0.53333rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
}

.btn-logout {
  padding: 8px 11px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--adm-color-danger);
}

.list_content {
  min-height: 600px;
  margin: 0 auto;
  padding: 3px;
  background-color: #ffffff;
  border-radius: 6px;
  font-size: 13px;
}

.list_header {
  display: flex;
  margin: 0 auto 15px;
  text-align: center;
  background-color: #eb7500;
  color: #fff;
  font-size: 13px;
  padding: 8px 3px;
  border-radius: 3px;
}

.history_page {
  .list_content {
    .list_header {
      &.item {
        background: #ffffff;
        color: #666;
        border-bottom: 1px solid #f1f1f1;
        margin: 0;
      }
    }
  }
}

.list-item {
  display: flex;
  margin-bottom: 10px;
}

.list_header .uni-text {
  flex-grow: 4;
  width: 25%;
}

.list_header .uni-text-1,
.list-item .uni-text-1 {
  width: 30%;
  text-align: center;
}

.list_header .uni-text-2,
.list-item .uni-text-2 {
  width: 70%;
  text-align: center;
  word-wrap: break-word;
}

.list_header .uni-text-3,
.list-item .uni-text-3 {
  width: 40%;
  text-align: center;
  word-wrap: break-word;
  border-left: 1px solid;
  border-right: 1px solid;
}

.list-item .uni-text {
  width: 33.33%;
  text-align: center;
  word-wrap: break-word;
  font-size: 14px;
}

.payment-container {
  padding: 20px;
  padding-top: 50px;
  min-height: 82vh;

  &.payment-form-page {
    background-color: #ffffff;
    min-height: calc(100vh - 130px);
  }

  .description {
    p {
      color: var(--theme-color);
      padding: 15px 0;
    }
  }

  .payType {
    border-bottom: 1px solid #f2f2f5;
  }

  .help-text {
    color: #ffffff;
    font-size: 10px;
    padding: 15px;
  }

  .payMoney {
    .label {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      color: var(--theme-color);
      font-size: 16px;
    }
  }

  .money {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;

    span {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.54);

      &.fieldMoney {
        font-size: 22px;
      }
    }

    .control-number {
      border: 1px solid #eeeeee;
    }
  }

  .remark {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
  }

  .balance {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    span {
      &:first-child {
        color: #4d4d4d;
      }
    }
  }

  .information {
    padding-bottom: 12px;
    border-bottom: 1px solid #cacaca;
  }

  .moneyNumber {
    display: flex;
    gap: 10px;
    padding: 20px 0;

    .btn {
      border-radius: 10px;
      border: 1px solid var(--theme-color);
      font-size: 13px;
      height: 50px;
      color: #000000;

      &.active {
        background-color: var(--theme-color);
        color: #ffffff;
      }
    }
  }

  .service {
    text-align: center;
    margin-top: 25px;
    font-size: 16px;
    color: #2b2b2b;

    a {
      margin-left: 5px;
      color: #ee0e0e;
      text-decoration: underline;
    }
  }

  p.title {
    margin-bottom: 5px;
  }

  .btn-sub {
    margin-top: 25px;
    width: 100%;
    height: 41px;
    border-radius: 4px;
    background-color: #cacacc;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bolder;
    border: none;
    line-height: 1.5;

    &.active {
      background-color: var(--theme-color);
    }
  }

  .bank-info {
    padding: 15px 0;
    border-bottom: 1px solid #cacaca;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      > span {
        width: 30%;
      }

      > div {
        width: 70%;
      }

      .btn-copy {
        height: 25px;
        padding: 0px 5px;
        font-size: 13px;
        border: 1px solid var(--theme-color);
        margin-left: 10px;
      }
    }
  }

  .inputMoney {
    padding: 15px 0;
    display: flex;
    font-size: 16px;

    .money {
      color: var(--theme-color);
      margin-left: 15px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #bebebe;
    padding-bottom: 15px;

    svg {
      font-size: 24px;
      color: #00d423;
    }
    p {
      font-size: 14px;
    }
    p.des {
      color: #757575;
    }
  }

  .note {
    display: flex;
    gap: 15px;
    padding: 15px 0;

    svg {
      font-size: 18px;
      color: #f7ac0c;
    }

    p {
      color: #979799;
      line-height: 22px;

      &.title {
        color: #922769;
      }
    }

    a {
      color: #f33d3d;
      text-decoration: underline;
    }
  }

  &.payment-withdraw-page {
    padding: 0;

    .payMoney {
      background-color: #ffffff;
      padding: 15px 20px;
      margin-top: 15px;
      color: var(--theme-color);

      .label {
        padding: 0;
        margin-bottom: 15px;
      }

      .control-number {
        color: #414141;
      }
    }

    .btn-sub {
      width: 90%;
      display: flex;
      justify-content: center;
      margin: 15px auto 0;
    }
  }

  &.bank-page {
    padding: 0;
  }

  &.bank-add-page {
    background-color: #ffffff;
    min-height: calc(100vh - 130px);
    font-size: 18px;
    color: #797878;
    padding-top: 45px;

    .label {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }

    .form-action {
      padding: 0;

      .form_label {
        width: 95px;
        font-size: 16px;
      }
    }
  }

  .btn-all {
    font-size: 18px;
  }

  .des-help {
    font-size: 16px;
    line-height: 16px;
    padding: 15px 0;
    color: #979799;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .help-content {
      svg {
        margin-right: 5px;
      }
    }
  }

  .tip {
    margin-top: 15px;
    font-size: 16px;
    padding: 0 20px;
    color: #979799;
  }

  .add-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    color: var(--theme-color);
    font-size: 20px;
    background-color: #ffffff;
    padding: 10px 20px;

    svg {
      margin-right: 15px;
    }
  }

  .card-info {
    width: 100%;
    color: var(--theme-color);
    font-size: 15px;
    background-color: transparent;
    padding: 10px 20px;
    margin-top: 20px;
    font-weight: 600;
    border-bottom: 1px solid #bdb780;

    &:last-child {
      border-bottom: none;
    }

    p {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;

      span.text-gray {
        font-size: 13px;
        color: #6b6b6b;
      }

      span.text-red {
        cursor: pointer;
      }
    }
  }

  .addBankCard {
    display: block;
    width: 100%;
    color: var(--theme-color);
    font-size: 18px;
    background-color: #ffffff;
    padding: 10px 20px;
    margin-top: 10px;

    svg {
      margin-right: 15px;
    }

    span {
      margin-right: 15px;
    }
  }
}

.title-cell {
  display: flex;
  align-items: center;
  color: var(--theme-color);

  img {
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }
}

.histories {
  padding: 0 20px;
  background-color: #ffffff;
  min-height: 82vh;

  .main-info {
    margin-top: 15px;

    .item {
      color: #2b2b2b;
      font-size: 18px;
      padding: 10px 0;
    }
  }

  .w-full {
    width: 100%;
  }

  .des {
    color: #4e4e4e;
    font-size: 18px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    color: #2b2b2b;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 18px;
      background-color: #775fd9;
      border-radius: 2px;
      margin-right: 7px;
    }
  }

  .account-card {
    background-color: #ffffff;
    padding: 15px 20px;
    display: flex;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      color: var(--theme-color);
      width: 65%;

      img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
    }

    .right {
      width: 35%;
    }
  }

  .btn-ticket {
    padding: 4px 12px;
    border: 1px solid #775fd9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #775fd9;
    border-radius: 15px;
  }

  .item {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;

    .left {
      width: 210px;
    }

    .right {
      color: #979799;
      font-size: 16px;

      svg {
        margin-left: 10px;
      }
    }

    .title {
      font-size: 16px;
      color: var(--theme-color);
    }

    .time {
      font-size: 16px;
      color: #979799;
    }
  }
}

.information-container {
  .menu-items {
    .item {
      .right {
        .avatar {
          width: 60px;
          height: 60px;
          margin-right: 10px;

          img {
            width: 100%;
            height: auto;
          }
        }

        span {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.update-info-container {
  padding-top: 45px;
  .content {
    padding: 20px;

    p {
      line-height: 1.5;
      margin-bottom: 5px;
      color: #5e5e5e;
    }
  }

  p.note {
    padding: 0 20px;
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
    color: #868686;
  }

  h5 {
    padding: 25px 0;
    color: var(--theme-color);
    text-align: center;
  }
}

.avatarbox {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 0 10px;
    color: var(--theme-color);
    font-size: 18px;
    border-bottom: 1px solid #e0e0e0;

    .btn {
      font-size: 18px;
      width: auto;
    }
  }
}

.px-6 {
  padding: 0 12px;
}

.list-avatar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 30px 0;

  .image {
    width: calc(20% - 15px);
    display: flex;
    justify-content: center;
    align-items: center;

    &.selected {
      img {
        border: 3px solid var(--theme-color);
      }
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.menu-items {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 0 20px;
  margin-bottom: 20px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e4e4e4;
    color: #666;
    font-size: 14px;

    &:last-child {
      border: none;
    }

    .left {
      svg {
        margin-right: 5px;
      }
    }

    .right {
      color: #8d8d8d;

      span {
        margin-right: 10px;
      }
    }
  }
}

.page-title {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  padding: 10px;
  border-bottom: 2px solid var(--theme-color);
  color: #f33d3d;
  text-align: center;
}

.content-block-title {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1;
  margin: 16px;
  line-height: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.54);
  margin: 14px 16px;
}

.list-bank {
  .bank-item {
    border-bottom: 1px solid #dbdbdb;
    padding: 15px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    .label-radio {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px;
      }

      input {
        // display: none;
        height: 20px;
        width: 20px;
      }
    }

    .item-title-row {
      min-width: 0;
      -webkit-box-flex: 1;
      flex-shrink: 1;
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: #8a9299;
      margin-bottom: 4px;
    }
  }
}

.text-red {
  color: #ff0000 !important;
}

.text-green {
  color: #00a81c !important;
}

.bankheader {
  width: 100%;
  height: 153px;
  background-color: #ffffff;
  display: flex;
  justify-content: center /* border: red 1px solid; */;
}

.bankcard {
  height: 150px;
  width: 90%;
  margin: 13px auto;
  background: linear-gradient(
    90deg,
    rgba(255, 132, 0, 1) 35%,
    rgba(255, 171, 0, 1) 100%
  );
  border-radius: 25px;
  padding: 10px;
  font-size: 14px;
}

.openbank {
  margin-top: 22px;
  font-weight: 600;
  color: #fff;
}

.bankname {
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  word-wrap: break-word;
  /*white-space: pre-wrap;*/
}

.holder {
  width: 100%;
  margin-top: 33px;
  background-color: #f0f0f0;
  color: #000;
  padding: 5px;
}

.uni-btn-primary {
  color: #fff;
  background-color: var(--theme-color);
  font-size: 14px;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 5px;

  .item {
    label {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 14px;
      line-height: 14px;
      margin-left: 5px;
    }
  }
}

.radio_control {
  width: 15px;
  height: 15px;
  margin: 0;
}

.message {
  font-size: 14px;
  line-height: 16px;
}
